@use 'sass:map';
@import '../../../CommonAssets/Styles/globalStyle';


$leftStyle : (
    'justifySelf': flex-start,
    'bubbleTopAreaPadding': 0 0 0 2vw,
    'bubbleMiddleAreaPadding': 0 0 0 1vw,
    'bubbleBottomAreaPadding': 0 0 0 3vw,
);

$rightStyle : (
    'justifySelf': flex-end,
    'bubbleTopAreaPadding': 0 0 0 0,
    'bubbleMiddleAreaPadding': 0 0 0 2vw,
    'bubbleBottomAreaPadding': 0 1vw 0 0,
);

.bubbleAndHeadArea {
    display: grid;
    align-items: center;
    grid-template-columns: [bubbleAndHead] 1fr;
    grid-template-rows: none;
}

.headImage {
    grid-area: bubbleAndHead;
    justify-self: center;
}

%bubbleContainerBase {
    display: grid;
    align-self: flex-start;
    z-Index: 1;
    grid-area: bubbleAndHead;
    grid-template-rows: 22% 22% 24% auto;
    gap: 1vh;
}

.bubbleContainerleft {
    @extend %bubbleContainerBase;
    justify-self: map.get($leftStyle, 'justifySelf');
}

.bubbleContainerright {
    @extend %bubbleContainerBase;
    justify-self: map.get($rightStyle, 'justifySelf');
}

.bubbleTopArealeft {
    grid-row: 1;
    padding: map.get($leftStyle, 'bubbleTopAreaPadding');
}

.bubbleTopArearight {
    grid-row: 1;
    padding: map.get($rightStyle, 'bubbleTopAreaPadding');
}

.bubbleMiddleArealeft {
    grid-row: 2;
    padding: map.get($leftStyle, 'bubbleMiddleAreaPadding');
}

.bubbleMiddleArearight {
    grid-row: 2;
    padding: map.get($rightStyle, 'bubbleMiddleAreaPadding');
}

.bubbleBottomArealeft {
    grid-row: 3;
    padding:  map.get($leftStyle, 'bubbleBottomAreaPadding');
}

.bubbleBottomArearight {
    grid-row: 3;
    padding:  map.get($rightStyle, 'bubbleBottomAreaPadding');
}

.bodyContainerAlignment{
    align-items: center;
}


