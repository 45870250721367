@import '../../../CommonAssets/Styles/globalStyle';

.maintenanceTextArea {
    margin: 1vh;
}

.maintenanceImageArea {
    margin-bottom: 5vh;
}

.maintenanceTimerArea {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: baseline;
    align-self: center;
    justify-content: center;
}

%timerText {
    color: get-color(black);
}

.maintenanceTimerTextSeperator {
    @extend %timerText;
}

.maintenanceTimerTextDigit {
    @extend %timerText;
    width: 5vw;
    margin: 10px;
    border: 0 solid #dddfdf;
    border-radius: 2px;
    outline: 1px solid #dddfdf;
    outline-offset: 4px;
}

.maintenanceTimerCaption {
    position: fixed;
    bottom: 5vh;
    color: get-color(black);
}