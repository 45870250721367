@import '../../../CommonAssets/Styles/globalStyle';

.buttonSelect {
    padding: 0.5rem 1rem;
}

.instructionStartText {
    margin-bottom: 5vh;
}

.resetInfoArea {
    align-items: center;
    justify-content: flex-end;
    margin: 0 8vw;
}

.resetInfoTextArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right: 0;
    padding: 2vh 2.9vw;
    border-radius: 8px;
    background-color: get-color(gray70);
}

.resetInfoTextDisabled {
    color: get-color(mistgray);
}

.resetInfoTextEnabled {
    color: get-color(black);
}

.arrowRight {
    width: 0;
    height: 0;
    border-top: 0.5rem solid transparent;
    border-bottom: 0.5rem solid transparent;
    border-left: 0.5rem solid get-color(gray70);
}

.finetuning-selection {
    border-radius: 8px;
}