@import '../../../CommonAssets/Styles/globalStyle';

.customerServiceImageSevereHearing {
    height: 7vh;
    max-height: 7vh;
    padding: 2vh 0 2vh 0;
}

.solutionCardSevereHearing {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    margin: auto 5vh;
    padding: 0 0 3vh 0;
    border-radius: 8px;
    background-color: get-color(lightorange);
    text-align: center;
    justify-self: stretch;
}

.solutionCardTitle,
.solutionCardLink {
    margin: 2vh 1vh;
    color: get-color(brownpod);
}

.customerServiceSupportLinkSevereHearing {
    text-decoration: underline;

    a:link { 
        color: get-color(brownpod);
    }
}

.customerServiceSupportButtonSevereHearing {
    width: 75%;
    margin: 0 auto;

    a:link {
        color: get-color(white);
    }
}

.severeHLinstruction {
    margin: 2vh auto;
}
