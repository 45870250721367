@import '../../HearingProfileShared.scss';

.instructionLeftRightDiffTop {
    margin: auto auto 5vh auto;
}

.instructionLeftRightDiffMiddle {
    margin: 0 auto 0 auto;
}

.instructionLeftRightDiffBottom {
    @extend %hearingProfileMarginBottomWithAuto;
}

.instructionLeftRightDiffImage {
    @extend %hearingProfileMarginAuto;
}