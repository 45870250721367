//Relative img size based on 667px height screen.
//This mapping aim to standardize img size between page and avoid large UI shift  
$imgSize: (
    'IconSmall': 2.4vh, //16px/667px
    'IconMediumSmall': 3.9vh, //26px/667px
    'Icon': 5.4vh, //36px/667px
    'IconMedium': 7.8vh, //52px/667px
    'IconMediumLarge': 10.2vh, //68px/667px
    'IconLarge': 11.2vh, //75px/667px
    'Small': 17vh, //110px/667px
    'MediumSmall': 25vh,
    'Medium': 32vh, //220px/667px
    'Large': 47vh, //320px/667px
);

@each $size, $imgHeight in $imgSize {
    .img#{$size} {
        height: $imgHeight;
        max-height: $imgHeight;
    }
} 

.imgFullWidth {   
    display: block;
    width: auto; 
    max-width: 100vw;
    height: auto;
    max-height: 100vh;
}