@import '../../CommonAssets/Styles/globalStyle';

.disabled {
    opacity: 0.5
}

.headerArea {
    align-items: center;
    min-height: 9.5vh;
    max-height: 11.5vh;
    padding: 2vh min(2vw, 80px);
    background-color: get-color(grayyellow);
}

.leftPadding {
    padding-left: 14%;
}

.rightPadding {
    padding-right: 14%;
}
