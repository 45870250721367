@import '../../CommonAssets/Styles/globalStyle';

.textLeftAlign {
    margin-left: 0;
    text-align: start
}

.textRightAlign {
    margin-right: 0;
    text-align: end
}

.textTopAlign {
    margin-top: 0;
}

.textBottomAlign {
    margin-bottom: 0;
}

.textTopLeftAlign {
    margin-top: 0;
    margin-left: 0;
    text-align: start
}

.textTopRightAlign {
    margin-top: 0;
    margin-right: 0;
    text-align: end
}

.textBottomLeftAlign {
    margin-bottom: 0;
    margin-left: 0;
    text-align: start
}

.textBottomRightAlign {
    margin-right: 0;
    margin-bottom: 0;
    text-align: end
}

.textNoMargin {
    margin: 0;
}

%comfortaa {
    font-family: get-font(system-ui);
}

%notoSans {
    font-family: get-font(system-ui);
}

%headerHeading {
    color: get-color(darkBlack);
    font-weight: 700;
}

%heading {
    color: get-color(black);
    font-weight: 700;
}

%body {
    color: get-color(black);
    font-weight: 400; //normal
}

.customSubHeading {
    font-size: 1.1rem;
    font-weight: 700;
}

.red {
    color: get-color(red);
}

.aqua {
    color: get-color(aqua);
}

.orange {
    color: get-color(orange);
}

.black {
    color: get-color(black);
}

.lightbrown {
    color: get-color(lightbrown);
}

.comfortaaHeaderHeading {
    @extend %headerHeading;
    @extend %comfortaa;
    font-size: 1.5rem;
}

.comfortaaHeading {
    @extend %heading;
    @extend %comfortaa;
    font-size: 1.5rem;
}

.comfortaaSubHeading {
    @extend %heading;
    @extend %comfortaa;
    font-size: 1.0rem;
}

.comfortaaBody {
    @extend %body;
    @extend %comfortaa;
    font-size: 0.8rem;
}

.notoSansHeading {
    @extend %heading;
    @extend %notoSans;
    font-size: 2rem;
}

.notoSansSubHeading {
    @extend %heading;
    @extend %notoSans;
    font-size: 1.3rem;
}

.notoSansBody12px {
    @extend %body;
    @extend %notoSans;
    font-size: 0.75rem; //12px = 0.75rem
}

.notoSansBody14px {
    @extend %body;
    @extend %notoSans;
    font-size: 0.875rem; //14px = 0.875rem
}

.notoSansBody16px {
    @extend %body;
    @extend %notoSans;
    font-size: 1rem; //16px = 1rem
}

.notoSansBody18px {
    @extend %body;
    @extend %notoSans;
    font-size: 1.125rem; //18px = 1.125rem
}

.notoSansBody20px {
    @extend %body;
    @extend %notoSans;
    font-size: 1.25rem; //20px = 1.25rem
}

.linkWithinText {
    text-decoration: underline;
}

.iconInText {
    position: relative;
    top: 0.4vh;
    margin: 0 0.4vh;
}