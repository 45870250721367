.customTableContainer {
    padding: 1vh 0;
    border: solid;
    border-width: 0.1em;
    border-color: #ededed;
}

.noBottomBorder {
    border-bottom: 0;
}

.customTableCell {
    padding: 1vh 0 1vh min(5.3vw, 42px);
}

.customTableImg {
    padding: 0;
}