.finetuninInfoSubHeading {
    margin: 0 0 4vh 0;
}

.noTopMargin {
    margin-top: 0;
}

.fineTuningInfoButton {
    text-transform: none;
}

.fineTuningInfoBulletList {

    ul {
        margin: -2vh 0 0 -4vw;
        text-align: left;
    }

    li {
        margin-top: 0;
        line-height: 1.3rem;
    }
}