.beepConfirmationTitle {
    margin: 2vh 0 6vh 0;
}

.beepConfirmationWithTopBar {
    margin: 2vh 0 11vh 0;
}

.beepConfirmationInstruction {
    margin: auto auto 0 auto;
}