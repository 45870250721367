@import '../../../CommonAssets/Styles/globalStyle';

.MuiDialog-container {
    position: sticky;
    background-color: transparent;
}

.MuiPaper-root {
    background-color: get-color(softyellow);
}

.MobileDeviceUnmutedCheckDialogBodyContainer {
    margin-right: 1vw;
    margin-left: 1vw;
}

.MobileDeviceUnmutedCheckDialogHeaderArea {
    align-items: center;
}

.MobileDeviceUnmutedCheckDialogTitleText {
    align-content: center;
    padding-left: 14%;
}

.MobileDeviceUnmutedCheckDialogCloseButton {
    align-content: flex-end;
}