.videoArea {
    position: relative;
    align-self: center;
    width: min(90vh, 90vw);
    height: max(30vh, 30vw);
    margin: 3vh 0;
    border-radius: 25px;
    overflow: hidden;

    &div > iframe {
        border-radius: 25px;
    }
}

.video {
    position: absolute;
    top: 0;
    left: 0;
}

.playButton {
    height: max(12vh, 12vw);
}

.flexCenterContainer {
    display: flex;
    justify-content: center;
}