@import '../../CommonAssets/Styles/globalStyle';

.bulletPoint {
    height: 0.75rem;
    margin: 0.31rem 0.3rem auto 0;
    color: get-color(black);
}

.menuItemIcon {
    height: 4.9vh;
    margin: 0 4vw 0 0;
}

.menuItemArrowIcon {
    height: 3.5vh;
}

.listItem {
    padding-top: 3vh;
    padding-bottom: 3vh;
}

.itemLeftAlign {
    padding-left: 0;
}