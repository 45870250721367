.titleMarginHearingAssessmentResult {
    margin-top: 2vh;
    margin-bottom: 0;
}

.marginBottomHearingAssessmentResult  {
    margin-top: 0
}

.imageMarginHearingAssessmentResult  {
    margin-top: 4vh;
    margin-bottom: 4vh;
}
