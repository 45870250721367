@import '../../CommonAssets/Styles/globalStyle';

.checkBoxArea {
    margin: 1vh 0;
}

.checkbox { 
    color: get-color(redorange);
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: get-color(redorange);
}

