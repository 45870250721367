@import '../../CommonAssets/Styles/globalStyle';

@mixin height() {
    height: 100vh;
    height: calc(100% * var(--vh));
}

@mixin minHeight() {
    min-height: -webkit-fill-available;
    min-height: calc(100 * var(--vh));
}

.textDefaultAlign {
    margin: auto;
    text-align: center;
}

.textWithWrap {
    white-space: pre-line;
    overflow-wrap: break-word;
}

.textNoWrap {
    white-space: pre;
    overflow-wrap: normal;
}

.baseFrameContainer {
    @include height();
    @include minHeight();
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: flex-start;
    box-sizing: border-box;
    width: 100vw;
    max-width: 800px;
    background-color: get-color(softyellow);
    // iOS https://css-tricks.com/css-fix-for-100vh-in-mobile-webkit/

    & * {
        touch-action: manipulation;
    }
}

.flexContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.flexRow {
    flex-direction: row;
}

.flexGrow {
    flex-grow: 1;
}

.bodyContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    margin: 2vh min(8vw, 80px);
}

.flexStart {
    justify-content: flex-start;
}

.noRowMargin {
    margin: 2vh 0;
}

.sticky {
    position: '-webkit-sticky'; /* Safari */
    position: sticky;
}

@mixin position() {
    display: flex;
    bottom: 0;
    justify-content: center;
    margin: 0 min(8vw, 80px);
    text-align: center;
}

@mixin background() {
    background: --ms-linear-gradient(
        to top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.6) 20%
    ); /* IE10+ */
    background: -o-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.6) 20%
    ); /* Opera 11.10 */
    background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.6) 20%
    ); /* Chrome10+,Safari5.1+ */
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, rgba(255, 255, 255, 0)),
        color-stop(20%, rgba(255, 255, 255, 0.6))
    ); /* Chrome,Safari4+ */
    background: -moz-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.6) 20%
    ); /* FF3.6+ */
}

@mixin doubleGradientBackground() {
    background: --ms-linear-gradient(
        to top,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 1.0) 30%,
        rgba(255, 255, 255, 0.6) 100%
    ); /* IE10+ */
    background: -o-linear-gradient(
        top,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 1.0) 30%,
        rgba(255, 255, 255, 0.6) 100%
    ); /* Opera 11.10 */
    background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 1.0) 30%,
        rgba(255, 255, 255, 0.6) 100%
    ); /* Chrome10+,Safari5.1+ */
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, rgba(255, 255, 255, 0.1)),
        color-stop(30%, rgba(255, 255, 255, 1.0)),
        color-stop(100%, rgba(255, 255, 255, 0.6))
    ); /* Chrome,Safari4+ */
    background: -moz-linear-gradient(
        top,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 1.0) 30%,
        rgba(255, 255, 255, 0.6) 100%
    ); /* FF3.6+ */
}


.actionContainer {
    @include position();
    @include background();

}

.actionStickyContainerDoubleGradient {
    @include position();
    @include doubleGradientBackground();
}

.buttonContainer {
    flex-direction: row;
    flex-grow: 1;
    margin: 2vh 0 2.5vh;
}
