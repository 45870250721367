@import '../../../CommonAssets/Styles/globalStyle';

.actionContainer.sticky {
    margin-top: 0;
    margin-bottom: 0;
    background-color: get-color(softyellow); 
}

.pairingTechnicalIssueBodyContainer {
    margin-top: 0;
    margin-bottom: 10px;
}

.pairingTechnicalIssueBottomHelpContainer {
    flex-grow: 0;
    margin: 0;
    padding: 2vh 0 2vh 0;
    background-color: get-color(grayyellow);
}

.pairingTechnicalIssueImportantNoteText {
    color: get-color(greyBrown);
    font-style: italic;
}

.pairingTechnicalIssueTitleText {
    margin: 0 -3vw;
    font-weight: 700;
}

.pairingTechnicalIssueInstructionText {
    margin: -1vh 2vw -1vh 2vw;
}
