@import '../../../CommonAssets/Styles/globalStyle';

.OneSideSetUp-runningArea {
    display: grid;
    align-items: center;
    align-self: center;
    margin: 0 auto 9vh;
    grid-template-columns: [running] 1fr;
    grid-template-rows: none;
    justify-items: center;
}

.OneSideSetUp-circularProgress {
    color: get-color(redorange);
    z-index: 1;
    grid-area: running;
}

.OneSideSetUp-image {
    grid-area: running;
    width: 5em;
    height: 5em;
}