@import '../../../../CommonAssets/Styles/globalStyle';

.MuiButtonBase-root {
    text-transform: none;
}

.welcome-subtitle {
    margin-top: 3vh;
    margin-bottom: 0;
}

.welcome-instruction {
    padding-top: 0;
}

.buttonNotFilled {
    background-color: get-color(softyellow);
}

.customHeader {
    margin-left: auto;
    padding-left: 3rem;
    font-weight: 400;
}