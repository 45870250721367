@import '../../../CommonAssets/Styles/globalStyle';

.splash-screen-background {
    position: absolute;
    width: 100vw;
    max-width: 800px;
    height: 100vh;
    z-index: 0;
    object-fit: cover;
}

.splash-screen-language-selection {
    align-self: flex-end;
    margin: 5vh min(10vw, 45px);
    background-color: get-color(whitegray);   
}

.splash-screen-language-icon {
    height: 4vh;
    margin: 0 1vw;
}

.splash-screen-welcome-header {
    margin: 33vh 0 3.5vh 0;
}

.splash-screen-welcome-message {
    margin-top: 3.5vh;
}

.splash-screen-cookie-policy {
    margin: 0 8vw 1.5vh 8vw;
    font-size: 1rem;
    line-height: 1rem;
    z-index: 1;
}

.splash-screen-button {
    text-transform: none;
}