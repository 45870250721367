@import '../../CommonAssets/Styles/globalStyle';

%commonButton {
    flex-grow: 1;
    margin: 1vh min(2vw, 25px);
    border-radius: 25px;
    font-family: get-font(system-ui);
    font-size: 1.125rem;
    text-transform: capitalize;
    cursor: pointer;
}

.buttonNotFilled {
    @extend %commonButton;
    border-color: get-color(black);
    color: get-color(gray);

    span {
        color: get-color(black);
    }
}

.buttonFilled {
    @extend %commonButton;
    background-color: get-color(brownpod);
    font-weight: 700;

    span {
        color: get-color(offwhite);
    }

    &:hover {
        background-color: get-color(brownpod);
    }
}

.buttonHalfScreen {
    width: 45%;
    max-width: 45%;
}

.flexColumn {
    flex-direction: column;
}

.buttonArrowRightAlign {
    margin-left: auto;
}

.forwardIcon {
    height: 2.4vh;
    max-height: 2.4vh;
    color: get-color(brownpod);
}

.disabled {
    opacity: 0.5;
}

.MuiButton-contained.Mui-disabled {
    background-color: get-color(brown10);
}
