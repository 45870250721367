@import '../../CommonAssets/Styles/globalStyle';

.stepperDots {
    background: transparent;
}

.MuiMobileStepper-dotActive.MuiMobileStepper-dot{
    background-color: get-color(brownpod);
}

.MuiMobileStepper-dot{
    width: 7px;
    height: 7px;
    border: solid 1px;
    border-color: get-color(brownpod);
    background-color: get-color(softyellow);
}

.carouselContainer {
    padding: 1vh 0;
}

.carouselDisplay {
    justify-content: space-between;
    margin-bottom: 4vh;
}

.carouselContentContainer {
    flex-direction: unset;
}

.arrowButton {
    padding: 0;
}

.instructionTextLeftAlign {
    margin: 0;
    padding: 16px;
    text-align: left;
}

ol {
    margin: 0;
    padding-left: 16px;
}

.instructionText {
    margin: min(10vw, 100px) min(8vw, 80px);
}

.hidden {
    opacity: 0%;
}