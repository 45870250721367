@import '../../../CommonAssets/Styles/globalStyle';

.editIcon {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 0;
    padding-right: 0.8rem;
}

.colonSeparator {
    padding: 0,
}

.disabled {
    opacity: 0.5;
}

.imgSettings {
    height: 8.8vh;
    margin: 3vh 0;
}

.beforeApplyButton {
    margin-bottom: 2vh;
}

.applyButtonText {
    color: get-color(white);
     
    div {

        a {
            @media (max-width: 370px) {
                font-size: 0.75rem;
            }
            color: get-color(white);
        }
    }
}

.spinnerContainerApplying {
    margin-top: 3vh;
}

.instructionsTextArea {
    margin: 0 8vw;
    padding: 3.5vh 0;
}

.appliedSuccessfullyTable {
    margin: 0 8vw;
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.defaultTable {
    margin: 0 8vw;
    border-radius: 8px;
}

.appliedSuccessfullyArea {
    align-items: center;
    margin: 0 8vw;
    padding: 8vh 0;
    border: solid;
    border-top: none;
    border-width: 0.1em;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-color: get-color(gray70);
}

.appliedSuccessfullyText {
    margin: 3vh;
}

.appliedSuccessfullyFooterText {
    margin-top: 5vh;
    margin-bottom: 3vh;
}

.solutionCard {
    width: min(70vw, 300px);    
    margin: 0 auto;
    padding-top: 2vh;
    border-radius: 8px;
    background-color: get-color(lightorange);
}

.imgFooterHome {
    margin-right: 1rem;
}

.finetuning-support-text {
    margin-bottom: 3vh;
    color: get-color(brownpod);
    text-decoration: underline;
}

.finetuning-webSupport {
    margin-top: 3vh;
}