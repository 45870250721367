@import '../../../CommonAssets/Styles/globalStyle';

.MuiDialog-container {
    position: sticky;
    background-color: transparent;
}

.MuiPaper-root {
    background-color: get-color(softyellow);
}

.partialDialogTitleText {
    margin: 3vw;
}

.partialDialogButtons {
    margin: 2vh 0;
    padding-top: 3vh;
}
