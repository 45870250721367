@import '../../../CommonAssets/Styles/globalStyle';

.TroubleShooting-image {
    margin-bottom: 5vh;
}

.TroubleShooting-customer-support-link {
    margin: 1vh auto 3vh auto;
}

.TroubleShootingIssueBottomHelpContainer {
    flex-grow: 0;
    margin: 0;
    padding: 2vh 0 2vh 0;
    background-color: get-color(grayyellow);
}
