@import '../../CommonAssets/Styles/globalStyle';

.hlaaStartBody {
    justify-content: start;
}

.hlaaTitle {
    margin: 0 auto 3vh;
}

.hlaaInstructionGeneral {
    margin-top: 1vh;
    margin-bottom: 6vh;
}

.hlaaImage {
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.hlaaPlayButtonInstruction {
    margin: 4vh auto 2vh;
}