@mixin sleeve-image {
    box-sizing: border-box;
    margin: 1vh;
    border-radius: 0.4vh;
}

.sleeve-image-not-selected {
    @include sleeve-image;
    border: 0.3vh solid #00b4bc;
}

.sleeve-image-selected {
    @include sleeve-image;
    border: 1vh solid #00b4bc;
}

.button-container {
    margin-top: 3vh;
}