@import '../../../CommonAssets/Styles/globalStyle';

.MuiButtonBase-root {
    text-transform: none;
}

.finetuning-complete-subtitle {
    margin-top: 3vh;
    margin-bottom: 2vh;
    color: #92817a;
}

.finetuning-complete-instruction {
    padding-top: 0;
}

.buttonNotFilled {
    background-color: get-color(softyellow);
}

.customHeader {
    margin-left: auto;
    padding-left: 3rem;
    font-weight: 700;
}
