@import '../../CommonAssets/Styles/globalStyle';

.pageLoadSpinner {
    background-color: get-color(softyellow);
    opacity: '0.5';
    z-index: $zIndex - 300;
}

.actionSpinner {
    color: get-color(white);
    z-index: $zIndex + 1;
}

%spinnerContainerGrid {
    display: grid;
    align-items: center;
    align-self: center;
    grid-template-columns: [running] 1fr;
    justify-items: center;
}

.spinnerContainer {
    @extend %spinnerContainerGrid;
    grid-template-rows: [running] [bottom];
}

.spinnerContainerPercent {
    @extend %spinnerContainerGrid;
    grid-template-rows: [running] 1fr [bottom] 1fr;
}

%progressCircular {
    grid-area: running;
}

.staticprogress {
    @extend %progressCircular;
    padding: 0.5em;
    border: 5em;
    border-radius: 50%;
    color: get-color(gray90);
    box-shadow: 1px 1px 4px 4px get-color(silver);
}

.movingprogress {
    @extend %progressCircular;
    color: get-color(redorange);
    z-index: 1;
}

.movingprogress-finetuning {
    @extend %progressCircular;
    color: get-color(lightx2brown);
    z-index: 1;
}

.displaytext {
    @extend %progressCircular;
    color: get-color(brownpod);
    font-family: system-ui;
    font-size: 1rem;
    z-index: 2;
}

.displaytextbottom {
    @extend %progressCircular;
    display: block;
    text-align: center;
    z-index: 2;
    grid-area: bottom;
} 

.displayimage {
    @extend %progressCircular;
}

.tickMark {
    height: 0.6em;
    margin: auto 0 auto 0.4em;

}

.circularProgress {
    color: get-color(brownpod);
}